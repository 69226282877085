<template>
  <div class="operationsAll">
    <div class="operationsNewFirst">
      <div class="operationsNewFirstLeft">
        <h1>护网行动</h1>
        <p>
          公司拥有经验丰富的红蓝队成员，在历届的国省市护网行动中获得过优秀成绩，同时公司与众多高校和社会安全团队达成深度合作，可同时派遣大量网络安全人才，定能在护网行动中祝您一臂之力。
        </p>
      </div>
      <div class="operationsNewFirstRight">
        <img src="../../../../assets/img/sofaFW/4.webp" alt="" />
      </div>
    </div>
    <div class="operationsNewSecond">
      <div class="operationsNewSecond1">
        <div class="operationsNewSecond1Left">
          <p>红队<span>以攻促防</span></p>
          <ul>
            <li style="text-indent: 1.5em; font-size: 14px; padding: 10px 0">
              采用入侵者的战术、技术、流程，以此来检验蓝队的威胁检测和应急响应的机制和效率，最终帮助企业真正提高整个安全建设、安全运营、安全管理等能力。
            </li>
            <li>
              •2020年帮助某四大行参与国家护网，在此期间打下专利局某域控，共获得1.5w+分数。
            </li>
            <li>
              •2020年帮助某四大行参与国家护网，在此期间打下专利局某域控，共获得1.5w+分数。
            </li>
            <li>
              •2021年参与兰州护网 打下某运营商运营的政企云总控 获取靶标权限
              ，荣获第一名。
            </li>
            <li>
              •2022年参加湖北省护网 打下楚天云，获取湖北政务云权限，荣获第三名。
            </li>
          </ul>
        </div>
        <div class="operationsNewSecond1Right">
          <p>蓝队<span>以防对攻</span></p>
          <ul>
            <li style="text-indent: 1.5em; font-size: 14px; padding: 10px 0">
              攻防演练模拟真实网络攻击，全方位多维度审视现有防护体系，以实战化的结果评估蓝队建设的成果，更加直观地暴露网络中存在的脆弱性和风险，更有效推动网络安全建设
            </li>
            <li>
              •2020年，某医院成功处置某信服近源渗透两起，通过网络溯源红队攻击队员一名，成功处置一起钓鱼事件。
            </li>
            <li>
              •2021年，某金融机构，成功渗透一个黑产网站并移交公安机关，通过网络溯源红队攻击队员三名。
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="operationsSecond">
      <h2>护网成果</h2>
      <div class="operationsFirst1">
        <img src="../../../../assets/img/pc/anquan/1.png" alt="" />
        <div class="operationsFirst1-1">
          <h3>2020年国家护网</h3>
          <ul>
            <li>
              •协助某四大行参加国家护网，拿下多个目标，其中成功进入专利局内网，
              最终打下域控获取靶标权限
            </li>
          </ul>
          <h3>2021年甘肃省护网</h3>
          <ul>
            <li>
              •协助深信服参与甘肃省护网，其中成功打下中国移动IDC机房并横向移动至政企云核心内网，最终打下靶标获得900+甘肃政企云机器
            </li>
          </ul>
          <h3>2022年湖北省护网</h3>
          <ul>
            <li>
              •协助深信服参与湖北省护网，其中成功进入楚天云核心内网并获得湖北政务平台和健康码平台权限
            </li>
          </ul>
        </div>
      </div>
      <p></p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
@media only screen and (max-width: 767px) {
  .operationsAll {
    width: 100%;
    display: flex;
    flex-direction: column;
    .operationsNewFirst {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f1f5ff;
      padding: 100px 40px;
      .operationsNewFirstLeft {
        width: 50%;
        h1 {
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 30px;
          text-align: center;
        }
        p {
          text-indent: 1.5rem;
          font-size: 14px;
          color: #51565d;
          line-height: 20px;
          margin-top: 10px;
        }
      }
      .operationsNewFirstRight {
        margin-left: 30px;
        img {
          width: 30vw;
          height: 26vh;
        }
      }
    }
    .operationsNewSecond {
      margin-top: 40px;
      background-image: url(../../../../assets/img/sofaFW/5.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      padding: 50px 40px;

      .operationsNewSecond1 {
        display: flex;
        flex-direction: column;

        .operationsNewSecond1Left {
          padding: 10px 40px;
          p {
            text-indent: 1.5rem;
            font-size: 16px;
            line-height: 20px;
            margin-top: 10px;
            color: #fff;
          }
          li {
            font-size: 14px;
            color: #fff;
          }
        }
        .operationsNewSecond1Right {
          padding: 30px 40px;
          p {
            text-indent: 1.5rem;
            font-size: 16px;
            color: #51565d;
            line-height: 20px;
            margin-top: 10px;
            color: #fff;
          }
          li {
            font-size: 14px;
            color: #fff;
          }
        }
      }
    }
    .operationsSecond {
      background: #f1f5ff;
      width: 100%;
      padding: 20px 40px;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .operationsFirst1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        .operationsFirst1-1 {
          padding: 20px 40px;
        }
        h2 {
          font-size: 16px;
          text-align: center;
          margin-bottom: 20px;
        }
        img {
          width: 53vw;
          height: 26vh;
        }
        h3 {
          padding: 5px 10px;
          margin: 10px 0;
          background-color: #484a4e;
          width: 220px;
          color: #fff;
          border-radius: 5px;
        }
      }
    }
    .operationsFirst {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .operationsSecond1 {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        .operationsSecond1Left {
          padding: 10px;
          width: 420px;
          background-color: #fe522c;
          border-radius: 5px;
          p {
            font-size: 28px;
            font-weight: 800;
            color: #fff;
          }
          li {
            font-size: 22px;
          }
        }
        .operationsSecond1Right {
          background-color: #0a91b3;
          border-radius: 5px;
          padding: 10px;
          width: 420px;
          margin-left: 20px;
          p {
            color: #fff;
            font-size: 28px;
            font-weight: 800;
          }
          li {
            font-size: 22px;
          }
        }
      }
    }
  }
}
</style>